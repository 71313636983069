import React from 'react'
const services = [
    {
        _id: '001',
        classIcon: 'ion-android-create',
        title: 'Content Writing',
        text: 'We are delivering 100+ articles daily'
    },
    {
        _id: '002',
        classIcon: 'ion-android-search',
        title: 'SEO',
        text: 'Improving your digital visibility'
    },
    {
        _id: '003',
        classIcon: 'ion-android-color-palette',
        title: 'Graphics Design',
        text: 'Logo, Web and App UI, Branding, Packaging, Ad & Promotion'
    },
    {
        _id: '004',
        classIcon: 'ion-android-playstore',
        title: 'Website, Portal & App',
        text: 'We provide programming solutions for all platforms under one roof'
    },
]
function Service2() {
    return (
        <>
            <section id="services" className="single-bg">
                <div className="section-block-title">
                    <div className="bg-dark"></div>
                    <div className="container">
                        <div className="section-sub-title center">
                            <article className="section-title-body white">
                                <h1 className="head-title">Services by<span> ProbitsLabs</span> </h1>
                            </article>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            {services.map((service) => (
                                <div className="col-xxl-6 col-md-6" key={service._id}>
                                    <div className="block-feature-icon left">
                                        <div className="icon hi-icon-color hi-icon-effect">
                                            <span className="hi-icon"><i className={service.classIcon}></i></span>
                                        </div>
                                        <div className="block-title">
                                            <h4>{service.title}</h4>
                                            <p>{service.text}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="h50"></div>
                </div>
            </section>
        </>
    )
}
export default Service2
