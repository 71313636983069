import React, { useState } from 'react'
import { Link } from 'react-scroll'
import logo from './../../images/probits-labs.jpg'

function Navbar1() {
    const [navToggle, setnavToggle] = useState(true);

    return (
        <>
            <nav className="navbar navbar-default navbar-expand">
                <div className="navbar-header">
                    <Link to='home' className="header-logo" smooth={false} duration={800}>
                        <span></span>
                        <img src={logo} alt="logo" />
                    </Link>
                </div>
                <div className={navToggle ? 'collapse navbar-collapse' : 'collapse navbar-collapse show'} id="bs-example-navbar-collapse-1">
                    <ul className="nav navbar-nav  navbar-right menu-item-5" id="navbar-full">
                        <li className="current home"><span className="rotated-title">Home</span><Link to='home' className="link-item " smooth={false} duration={800} activeClass="active">Home</Link></li>
                        <li className="about"><span className="rotated-title">About</span><Link to='about' className="link-item" smooth={false} duration={800} activeClass="active">About</Link></li>
                        <li className="service"><span className="rotated-title">Services</span><Link to='services' className="link-item" smooth={false} duration={800} activeClass="active">Service</Link></li>
                        <li className="work"><span className="rotated-title">Works</span><Link to='portfolio' className="link-item" smooth={false} duration={800} activeClass="active">Works</Link></li>
                        <li className="contact"><span className="rotated-title">Contact</span><Link to='contact' className="link-item" smooth={false} duration={800} activeClass="active">Contact</Link></li>
                    </ul>
                </div>
            </nav>
        </>
    )
}

export default Navbar1
