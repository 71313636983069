import React from 'react'
import ContactForm from './../Contact/ContactForm'
import SocialMedia from './SocialMedia'

function Contact2() {
    return (
        <>
            <section id="contact" className="single-bg">
                <div className="bg-dark"></div>
                <div className="block">
                    <div className="section-sub-title center">
                        <article className="section-title-body white">
                            <h1 className="head-title">Can you <span>Contact</span> us</h1>
                        </article>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 ">
                                <div className="text-center white">
                                    <h4 className="widget-title"><i className="ion-home"></i>address</h4>
                                    <p>
                                        Netaji Nagar, Bansdroni, Kolkata, India
                                    </p>
                                    <h4 className="widget-title"><i className="ion-android-call"></i>phone</h4>
                                    <p>+91 96812 85260<br />
                                        +91 82404 60910
                                    </p>
                                    <h4 className="widget-title"><i className="ion-email"></i>email</h4>
                                    <p> info@probitslab.com<br />
                                        theprobitslabs@gmail.com
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <ContactForm />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default Contact2
