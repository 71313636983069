import React from 'react'

function Welcome1() {
    return (
        <section>
            <div className=" color-scheme-2 ">
                <div className="bg-50-l not-before"></div>
                <div className="bg_object1 lax lax_preset_fadeIn:50:100 lax_preset_spin:4000:270  lax_preset_slideY:2500:700"></div>
                <div className="container-fluid">
                    <div className="row  justify-content-center">
                        <div className="col-md-10 col-lg-8">
                            <div className="pad-l-45 pad-r-45">
                                <div className="section-block-title">
                                    <div className="section-title">
                                        <article className="section-title-body">
                                            <h1 className="head-title wow fadeInDown animated" data-wow-duration="1.5s">DELIVERING DIGITAL EXCELLENCE</h1>
                                            <h1 className="head-title wow fadeInDown animated" data-wow-duration="1s">Dedicated Team with 14+ years of Experience</h1>
                                            <div className="section-col wow fadeInUp animated" data-wow-duration="1.5s"></div>
                                            <h1 className="head-title wow fadeInDown animated" data-wow-duration="1.5s">We deliver unparalleled user experience (UX) via Mobile Apps, Websites &amp; Portals.</h1>
                                            <h1 className="head-title wow fadeInDown animated" data-wow-duration="1.5s">We write contents that Google loves. Our efficient team delivers 100+ SEO contents every day.</h1>
                                        </article>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default Welcome1
