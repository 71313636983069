import React from 'react';
import Header1 from './../components/Header/Header1';
import Welcome1 from './../components/Welcome/Welcome1';
import About1 from './../components/About/About1';
import Service2 from './../components/Service/Service2';
import Graphics from './../components/Graphics/Portfolio1';
import Development from './../components/Development/Portfolio1';
import Contact2 from './../components/Contact/Contact2';
import Clients from './../components/Clients/Clients';
// import Map from './../components/Map';
import Footer1 from './../components/Footer/Footer1';
import SimpleSlider1 from './../components/Sliders/Slick-slider1'

function Home1() {
    return (
        <>
            <div id="home" className="full-screen-block">
                <Header1 />
                <SimpleSlider1 />
            </div>
            <Welcome1 />
            <Service2 />
            <About1 />
            <Graphics />
            <Development />
            <Clients />
            <Contact2 />
            {/* <Map /> */}
            <Footer1 />
        </>
    );
}

export default Home1;