import React from 'react'
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";

const options = {
    thumbnails: {
        showThumbnails: true,
        thumbnailsAlignment: 'center',
        thumbnailsContainerBackgroundColor: 'transparent',
        thumbnailsContainerPadding: '0',
        thumbnailsGap: '0 1px',
        thumbnailsIconColor: '#ffffff',
        thumbnailsOpacity: 0.4,
        thumbnailsPosition: 'bottom',
        thumbnailsSize: ['100px', '80px']
    },
    buttons: {
        backgroundColor: 'rgba(30,30,36,0.8)',
        iconColor: 'rgba(255, 255, 255, 0.8)',
        iconPadding: '10px',
        showAutoplayButton: true,
        showCloseButton: true,
        showDownloadButton: false,
        showFullscreenButton: true,
        showNextButton: true,
        showPrevButton: true,
        showThumbnailsButton: true,
        size: '40px'
    }
}
function Lightbox() {
    return (
        <>
            <SimpleReactLightbox>
                <SRLWrapper options={options}>
                    <div className="row  gx-5">
                        <div className="col-xl-6 col-md-12">
                            <div className="single-portfolio-block">
                                <a href="img/profile/graphics/logo.png">
                                    <img className="img-fluid" alt="logo" src="img/profile/graphics/logo.png" width={"100px"} />
                                </a>
                                <span className="single-portfolio-title">logo</span>
                            </div>
                        </div>
                        <div className="col-xl-6 col-md-12">
                            <div className="single-portfolio-block">
                                <a href="img/profile/graphics/icon.png">
                                    <img className="img-fluid" alt="icon" src="img/profile/graphics/icon.png" width={"100px"} />
                                </a>
                                <span className="single-portfolio-title">icon</span>
                            </div>
                        </div>
                        <div className="col-xl-6 col-md-12">
                            <div className="single-portfolio-block">
                                <a href="img/profile/graphics/banner.jpg">
                                    <img className="img-fluid" alt="logo" src="img/profile/graphics/banner.jpg" width={"100px"} />
                                </a>
                                <span className="single-portfolio-title">Social Media Banner</span>
                            </div>
                        </div>
                        <div className="col-xl-6 col-md-12">
                            <div className="single-portfolio-block">
                                <a href="img/profile/graphics/brochure.jpg">
                                    <img className="img-fluid" alt="logo" src="img/profile/graphics/brochure.jpg" width={"150px"} />
                                </a>
                                <span className="single-portfolio-title">brochure</span>
                            </div>
                        </div>
                    </div>
                </SRLWrapper>
            </SimpleReactLightbox>
        </>
    )
}

export default Lightbox
