import React from 'react'
// nir...
import '../../common/nir.css'
function GetLogo() {
    var logos = [];
    for (var i = 1; i <= 11; i++) {
        logos.push(
            <li className='list-horizontal' key={i}>
                <img className="img-fluid" alt="Comfort Office tools" width={"100px"} src={"img/client/logo" + i + ".gif"} />
            </li>
            // " <span>neeraj</span>"
        )
    }
    // console.log("logos")
    return logos;
}
export default GetLogo