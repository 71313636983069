import React from 'react';
import SocialMedia from '../Contact/SocialMedia';
import logo from './../../images/probits-labs.jpg'

function Footer1() {
    return (
        <>
            <footer id="footer">
                <div className="pad-l-45 pad-r-45">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xl-3 col-md-6">
                                <article className="footer-info-block">
                                    <div className="section-sub-title">
                                        <article className="section-title-body white">
                                            <h4 className="head-title">About</h4>
                                        </article>
                                    </div>
                                    <p>We deliver unparalleled user experience (UX) via Mobile Apps, Websites &amp; Portals. <br /> We write contents that Google loves. Our efficient team delivers 100+ SEO contents every day.</p>
                                </article>
                            </div>
                            <div className="col-xl-3 col-md-6">
                                <article className="footer-info-block">
                                    <div className="section-sub-title">
                                        <article className="section-title-body white">
                                            <h4 className="head-title">Web &amp; App</h4>
                                        </article>
                                    </div>
                                    <p>We provide programming solutions for Web &amp; App in all platforms under one roof</p>
                                </article>


                            </div>
                            <div className="col-xl-3 col-md-6">
                                <article className="footer-info-block">
                                    <div className="section-sub-title">
                                        <article className="section-title-body white">
                                            <h4 className="head-title">Graphics Design</h4>
                                        </article>
                                    </div>
                                    <p>Logo, Web and App UI, Branding, Packaging, Ad &amp; Promotional design</p>
                                </article>
                            </div>
                            <div className="col-xl-3 col-md-6">
                                <article className="footer-info-block">
                                    <div className="section-sub-title">
                                        <article className="section-title-body white">
                                            <h4 className="head-title">SEO &amp; Content Writing</h4>
                                        </article>
                                    </div>
                                    <p>
                                        We are delivering 100+ articles daily and improving your digital visibility accross the virtual world.
                                    </p>
                                </article>
                            </div>
                        </div>
                        <div className="h25"></div>
                        <div className="footer-bottom">
                            <div className="row">
                                <div className="col-md-8 order-1 col-xs-12 col-sm-8">
                                    <SocialMedia />
                                </div>
                                <div className="col-md-4 col-xs-12 col-sm-4">
                                    <div className="footer-logo">
                                        {/* <img src="img/logo-big-shop1.png" alt="" /> */}
                                        <img src={logo} alt="logo" />

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer1
