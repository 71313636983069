import React from 'react'
import bg_vide from '../../videos/bigway.mp4'

function About1() {
    return (
        <>
            <section id="about" className="bg-non-fixed">
                <video autoPlay loop muted className="bg_video">
                    <source src={bg_vide} type="video/mp4"></source>
                </video>
                <div className="bg-inner-dark"></div>
                <div className="bg-50-r"></div>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-6 col-sm-12 d-flex align-items-center">
                            <div className="pad-l-45 pad-r-45">
                                <div className="section-block-title">
                                    <div className="section-title">
                                        <article className="section-title-body mobile-mb-0">
                                            <h1 className="head-title wow fadeInDown animated" data-wow-duration="1.5s">About Us</h1>
                                            {/* <div className="section-col wow fadeInUp animated" data-wow-duration="1.5s"></div> */}
                                            <p className="wow fadeInUp animated" data-wow-duration="1.5s" style={{ textAlign: 'justify' }}>
                                                At Pro Bits Labs, we believe that you need not spend thousands on several platforms,
                                                which ultimately will not offer fruitful and satisfactory results. From web design to effective
                                                SEO services, we are your one-stop-shop for all business related requirements. We ensure that
                                                you attain adequate reach effortlessly.
                                                We have been operating in the industry successfully for many years. Top notch quality, timely
                                                delivery of services and customer satisfaction are our primary concerns. Our experts will are
                                                open to your suggestions, and will work on the core idea for better affectivity. We believe in
                                                keeping all communications open for the convenience of the clients.
                                                Finally, we are dedicated to offer services to both big and small business organizations. We will
                                                create perfect service packages at affordable rates. No matter what your budget, you
                                                can be rest assured about service quality.
                                            </p>
                                        </article>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <div className="pad-l-45 pad-r-45 block">
                                <div className="clearfix"></div>
                                <div className="text-left white">
                                    <h4 className="widget-title"><i className="ion-home"></i>Content Development</h4>
                                    <ul>
                                        <li className="widget-text">
                                            Creative Writing / Articles, Product Review, Blogs
                                        </li>
                                        <li className="widget-text">
                                            Academic Writing, Technical Writing
                                        </li>
                                        <li className="widget-text">
                                            Marketing Content , SEO Articles, E-Books, Web Content
                                        </li>
                                    </ul>
                                    <h4 className="widget-title"><i className="ion-home"></i>Branding</h4>
                                    <ul>
                                        <li className="widget-text">
                                            Logo, Business Card, Letterhead, Envelope
                                        </li>
                                        <li className="widget-text">
                                            Emailer, Brochures, Product Packaging
                                        </li>
                                    </ul>
                                    <h4 className="widget-title"><i className="ion-home"></i>2D/3D Graphics</h4>
                                    <ul>
                                        <li className="widget-text">
                                            Web/Print Graphics , Digital Illustration
                                        </li>
                                        <li className="widget-text">
                                            Application UI (Web / Mobile), Website Layouts
                                        </li>
                                        <li className="widget-text">
                                            2D Animation, 3D Modeling & animation, Walkthrough
                                        </li>
                                    </ul>
                                    <h4 className="widget-title"><i className="ion-home"></i>Web Development </h4>
                                    <ul>
                                        <li className="widget-text">
                                            Websites, Portals, Ecommerce, CMS, LMS
                                        </li>
                                    </ul>
                                    <h4 className="widget-title"><i className="ion-home"></i>Digital Marketing & Search Engine Optimization (SEO)</h4>
                                    <ul>
                                        <li className="widget-text">
                                            On-Site SEO Services
                                        </li>
                                        <li className="widget-text">
                                            Off-Site SEO Services
                                        </li>
                                        <li className="widget-text">
                                            Social Media Optimization & Marketing
                                        </li>
                                        <li className="widget-text">
                                            Paid Promotions & Campaigns
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default About1
