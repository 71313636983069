import React from 'react'
import {
    FacebookShareButton,
    PinterestShareButton,
    TelegramShareButton,
    LinkedinShareButton,
    TwitterShareButton
} from "react-share";
import {
    FacebookIcon,
    PinterestIcon,
    TelegramIcon,
    LinkedinIcon,
    TwitterIcon
} from "react-share";

function SocialMedia() {
    return (
        <>
            <ul className="footer-icon list-unstyled">
                <li>
                    <FacebookShareButton url="#">
                        <FacebookIcon round={true} size={32}></FacebookIcon>
                    </FacebookShareButton>
                </li>
                <li>
                    <PinterestShareButton url="#">
                        <PinterestIcon round={true} size={32}></PinterestIcon>
                    </PinterestShareButton>
                </li>
                <li>
                    <LinkedinShareButton url="#" >
                        <LinkedinIcon round={true} size={32}></LinkedinIcon>
                    </LinkedinShareButton>
                </li>
                <li>
                    <TwitterShareButton url="#">
                        <TwitterIcon round={true} size={32}></TwitterIcon>
                    </TwitterShareButton>
                </li>
                <li>
                    <TelegramShareButton url="#">
                        <TelegramIcon round={true} size={32}></TelegramIcon>
                    </TelegramShareButton>
                </li>
            </ul>
        </>
    )
}

export default SocialMedia
