import React from 'react'
import Lightbox from './Lightbox'

function Development() {
    return (
        <>
            <section id="development" className="bg-non-fixed">
                <div className="bg-inner-dark2"></div>
                <div className="bg-50-l"></div>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-6 col-sm-12 col-xs-12  order-md-1 justify-content-center d-flex align-items-center">
                            <div className="pad-l-45 pad-r-45">
                                <div className="section-block-title">
                                    <div className="section-title">
                                        <article className="section-title-body mobile-mb-0">
                                            <h1 className="head-title wow fadeInDown animated" data-wow-duration="1.5s">Website & App Development</h1>
                                            {/* <div className="section-col wow fadeInUp animated" data-wow-duration="1.5s"></div> */}
                                            <p className="head-text wow fadeInUp animated" data-wow-duration="1.5s" style={{ textAlign: 'left' }}>
                                                <ul>
                                                    <li> Server Side: PHP </li>
                                                    <li> Data Base: MySQL, Oracle, PostgreSQL, Firebase </li>
                                                    <li> Client Side: HTML, CSS, JS, jQuery, Node JS, Angular JS, React JS </li>
                                                    <li> PHP Framework: Laravel </li>
                                                    <li> PHP CMS: WordPress, Shopify </li>
                                                    <li> App: React Native, Flutter </li>
                                                </ul>
                                            </p>
                                        </article>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-12 col-xs-12">
                            <div className="pad-l-45 pad-r-45 block">
                                <Lightbox />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Development
